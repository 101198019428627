import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Germany",
  "subtitle": "November 2012",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`I was here for the Christmas markets!`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Baumkuchen"
        }}>{`Baumkuchen`}</a>{` - This cake is actually really popular in Japan`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Flammekueche"
        }}>{`Flammkuchen`}</a>{` - German Pizza is what I would call it`}</li>
    </ul>
    <h2 {...{
      "id": "berlin"
    }}>{`Berlin`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/mnv9udf8YhFb1Q697"
        }}>{`Il Ritrovo - Cucina Casalinga Popolare`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/miuhwYQ1wEMC6kF5A"
        }}>{`Café Anna Blume`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/x3Y4mGpZGJLKvnW88"
        }}>{`Deutsches Technikmuseum`}</a>{` - Huge and fascinating museum`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VLEwEcj3BAMcERaA6"
        }}>{`Neues Museum`}</a>{` - 19th-century museum with collections of Egyptian artwork & prehistoric archaeology; Even with an audio guide, I wasn't able to explore the whole place`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FTGt73f45g97kB88A"
        }}>{`Pergamonmuseum`}</a>{` - Same area as Neues Museum; I believe there might be a museum pass as well...`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MSCKKgtgFBQUQALJ9"
        }}>{`Berlin Wall Memorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/GaUe5Q8MxTaqXQFx5"
        }}>{`Berlin Cathedral`}</a>{` - Climb stairs for view`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MCBGBvMLTBEzgouD8"
        }}>{`Checkpoint Charlie`}</a></li>
      <li parentName="ul">{`Street art tour`}</li>
    </ul>
    <h4 {...{
      "id": "holiday-markets"
    }}>{`Holiday markets`}</h4>
    <ul>
      <li parentName="ul">{`Alexanderplatz - Not so posh, but very colorful and had a Ferris wheel`}</li>
      <li parentName="ul">{`Gendarmenmarkt - Posh but interesting`}</li>
      <li parentName="ul">{`Potsdamer Platz - Had an ice skating rink and a snow tubing area (?)`}</li>
      <li parentName="ul">{`Charlottenburg Palace - Palace was beautiful at night`}</li>
      <li parentName="ul">{`Gedachtniskirche - I had written the word ghetto on my paper map but I don't recall`}</li>
    </ul>
    <h2 {...{
      "id": "münchen"
    }}>{`München`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://int.bahn.de/en/offers/regional/regional-day-ticket-bavaria"
        }}>{`Bayern Ticket`}</a>{` is a great way to train around Bavaria`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4YWbJ7ctsHj3oSAz9"
        }}>{`Chocolate & More`}</a>{` - Hot chocolate was good`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gxhz9qrMgGoHtnC97"
        }}>{`Hofbräuhaus am Platzl`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nPJhoVpxRj5pfCLh8"
        }}>{`Rathaus-Glockenspiel`}</a>{` - Clock tower show in Marienplatz retelling `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Rathaus-Glockenspiel"
        }}>{`history`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/v8NLD1tke8uQEhuLA"
        }}>{`Deutsches Museum`}</a>{` - I liked the one in Berlin more`}</li>
    </ul>
    <h4 {...{
      "id": "holiday-markets-1"
    }}>{`Holiday markets`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.mittelaltermarkt-muenchen.de/"
        }}>{`Mittelaltermarkt`}</a>{` - I had a lto of fun at this Medieval market and the food was very unique`}</li>
      <li parentName="ul">{`Marienplatz - So-so`}</li>
    </ul>
    <h2 {...{
      "id": "nuremberg"
    }}>{`Nuremberg`}</h2>
    <ul>
      <li parentName="ul">{`Known as toy town. There’s some serious toy shopping available!`}</li>
      <li parentName="ul">{`The evening train was super packed so don’t show up at the last minute or you'll stood the whole way back.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4zAR1V5RvXPjWCX2A"
        }}>{`Kinderweihnacht`}</a>{` - Holiday market`}</li>
    </ul>
    <h2 {...{
      "id": "neuschwanstein"
    }}>{`Neuschwanstein`}</h2>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/edGKYkubMf1E2uFv6"
        }}>{`castle`}</a>{` that gave Disney some inspiration`}</li>
      <li parentName="ul">{`Must use guided tour which was very short`}</li>
    </ul>
    <h2 {...{
      "id": "heidelberg"
    }}>{`Heidelberg`}</h2>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LxA8CGKbQAQeBRuB8"
        }}>{`Café Konditorei Schafheutle`}</a>{` - Desserts were good`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TGkH4buKWNEaSDtaA"
        }}>{`Bier Brezel`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/tqG2jW8pyQFc5XGZA"
        }}>{`Philosophenweg`}</a>{` - Known as the “Philosopher’s Walk” on the northern banks of the Neckar River and had a lovely view of town`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/cEJEWyECmNJmJapXA"
        }}>{`Schloss Heidelberg`}</a>{` - There was a massive wine barrel here!`}</li>
    </ul>
    <h2 {...{
      "id": "köln"
    }}>{`Köln`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HqJghvAYACq3v12e8"
        }}>{`die wohngemeinschaft`}</a>{` - It's both a hostel, cafe, and a theatre`}</li>
    </ul>
    <h3 {...{
      "id": "food-4"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wXWvjiBFoLjCGSc1A"
        }}>{`Merzenich`}</a>{` - Bakery was extremely busy`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bNT838mfbD3ezLcN9"
        }}>{`Cafecafe`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VTEqwcUQztehoaCX6"
        }}>{`Rautenstrauch-Joest Museum`}</a>{` - Highly recommend!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/A5DdNqRPWesKwRwL9"
        }}>{`Schokoladen Museum`}</a>{` - Lindt Chocolate Museum!!!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PBmJZPricYKUXFaD8"
        }}>{`Kölner Dom`}</a>{` - Head to the top for a nice view`}</li>
    </ul>
    <h4 {...{
      "id": "holiday-markets-2"
    }}>{`Holiday markets`}</h4>
    <ul>
      <li parentName="ul">{`Kölner Dom - This one is gorgeous at night!`}</li>
      <li parentName="ul">{`Neumarkt - Very well lit`}</li>
      <li parentName="ul">{`Rudolfplatz - My paper map notes said fairytales but I don't know what it means anymore`}</li>
      <li parentName="ul">{`Kölner Altstadt (Heimat der Heinzel)`}</li>
      <li parentName="ul">{`Alter Markt`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      